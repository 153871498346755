$nav-link-bg-hov: #105CA7;
$nav-dropdown-hov: #0B4C8C;

// -----------------------------------//
// NAVIGATION WRAPPERS
// -----------------------------------//

nav#navholder {

	position: relative;
	z-index: 400;
	@extend .clearfix;
	background: #105CA7;

	>.wrapper {
		width: 1435px;
		margin: 0 auto;
		max-width: 100%;
	}
}

// -----------------------------------//
// MAIN NAVIGATION
// -----------------------------------//

ul.nav {
	display: table;
	width: 100%;
	margin: 0 auto;
	list-style: none;
	max-width: 100%;

	// Top Level List
	li {
		margin: 0;
		background: none;
		position: relative;
		display: inline-block;
		text-align: center;
		white-space: nowrap;
		padding: 0;

		a {
			height: 50px;
			line-height: 50px;
			display: block;
			text-align: center;
			font-size: 15px;
			color: #fff;
			padding: 0 10px;
		}
	}

	>li:hover>a,
	>li>a:hover {
		background-color: #0B4C8C;
		box-shadow: inset 0 -3px 0 #fff;
		color: #fff;
		text-decoration: none;
	}

	/* NEST INSIDE 2ND LEVEL LIST ITEMS  ( includes variables ) */

	// 2nd Level List
	li {
		ul {
			position: absolute;
			left: -999em;
			margin: 0;
			padding: 0 20px 20px;
			background: none;
			opacity: 0;
			visibility: hidden;
			transition: (all 0.3s ease-in-out);
		}

		&:hover ul {
			left: auto;
			margin-left: -20px;
			opacity: 1;
			visibility: visible;
			transition: (all 0.3s ease-in-out);
		}

		&:active ul {
			transition: (opacity 0.1s ease-in-out);
		}

		// change direction of last three items

		&:last-of-type,
		&:nth-last-of-type(2),
		&:nth-last-of-type(3) {

			li {
				a.parent {
					padding-left: 30px;
				}

				&:hover li a {
					padding-left: 12px;
				}
			}

			li a.parent:after {
				position: absolute;
				left: 13px;
				top: 13px;
				content: url('/i/design/lite/nav-right-arrow-white.png');
				opacity: 1;
				line-height: 1;
				transform: rotate(180deg);
				margin: 0;
			}

			ul {
				left: -50px;
				pointer-events: none;
			}

			&:hover ul {
				left: auto;
				right: 0;
				margin-right: -20px;
				pointer-events: all;
				z-index: 999;
			}

			&:hover ul ul {
				right: 100%;
				left: auto;
			}
		}
	}

	// 2nd Level List Items
	li li {
		float: none;
		display: block;
		padding: 0;
		height: auto;
		line-height: 35px;

		a {
			background: $nav-link-bg-hov;
			height: 39px !important;
			line-height: 28px !important;
			padding: 6px 12px;
			min-width: 150px;
			float: none;
			top: auto;
			display: block;
			position: static;
			text-align: left;
			text-decoration: none;
			font-size: 14px;
			text-transform: none;
			color: #fff;
		}

		&:hover,
		&:hover>a,
		a:hover,
		a:focus {
			background: $nav-dropdown-hov;
		}
	}

	// Prepend 'more' arrow to parent list items
	li li a.parent:after {
		margin: -1px 0 0 10px;
		content: url('/i/design/lite/nav-right-arrow-white.png');
		opacity: 1;
		line-height: 1;
	}

	// 3rd Level List
	ul ul {
		z-index: 505;
		padding-top: 0;
	}

	li:hover ul ul {
		left: 100%;
		top: 0;
		opacity: 0;
		visibility: hidden;
		transition: (all 0.3s ease-in-out);
	}

	ul li:hover ul {
		left: 100%;
		top: 0;
		opacity: 1;
		visibility: visible;
		transition: (all 0.3s ease-in-out);
	}
}

ul.nav li:hover ul {
	z-index: 999;
}

.fixed {
	position: fixed !important;
	animation: move-down 1s ease forwards;
	background: #105CA7 !important;
	width: 100%;

	@keyframes move-down {
		from {
			top: -50px;
			opacity: 0;
		}

		to {
			top: 0;
			opacity: 1;
		}
	}
}