.partnership-trust {
	display: none;

	position: absolute;
	right: 12%;
	top: -190px;
	width: 275px;
	height: 305px;
	background: #f8f8f8 url(/i/design/trust-tab.jpg) bottom center no-repeat;
	cursor: pointer;
	transition: 1s !important;
	z-index: 999;
	background-size: contain;

	p {
		opacity: 0;
		color: #222;
		width: 90%;
		font-size: 16px;
		line-height: 1.1;
		transition: 1s;
		font-weight: 300;
		margin: 20px auto 20px;
	}

	a {
		font-weight: bold;
		display: block;
		color: #134383;
		font-size: 15px;
	}

	.arrow {
		position: absolute;
		content: '';
		left: 0;
		right: 0;
		bottom: 2px;
		margin: 0 auto;
		width: 20px;
		height: 10px;
		background: url(/i/design/trust-arrow.png) no-repeat center;
		background-size: contain;
	}
}

.trust-extended {
	transition: 1s !important;
	top: 0;

	p {
		opacity: 1;
		transition: 1s;
	}

	.flip-it {
		transform: rotate(180deg) !important;
	}

}