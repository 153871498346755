// -----------------------------------//
// ELEMENTS COLOURS
// -----------------------------------//
$slideOut-panel-bg: rgba(255, 255, 255, .8);
$link-bg-colour: navy;
$link-bg-colour-hover: rgba(255, 255, 255, .2);
$links-colour: #fff;

$slide-click-colour: #105CA7;

$mobile-tab-color: #105CA7;

/* Full container */
#slideOut {
	position: fixed;
	width: auto;
	height: auto;
	min-height: 355px;
	top: calc(50% - 100px);
	margin: 0 !important;
	left: -250px;
	background: url(/i/design/ql-bg.png) no-repeat top right;
	padding: 0px;
	z-index: 999;
	overflow: visible;
	padding: 20px 0;
}


/* Click to slide */
#slideClick {
	float: right;
	height: 100%;
	width: 90px;
	cursor: pointer;
	position: relative;

	span {
		width: 160px;
		height: 70px;
		position: absolute;
		top: 40%;
		left: 50%;
		font-weight: bold;
		font-size: 30px;
		color: $slide-click-colour;
		white-space: nowrap;
		transform: rotate(-90deg);
		margin-left: -83px;
		margin-top: -37px;

		&:before {
			content: "";
			width: 40px;
			height: 40px;
			background: url(/i/design/arrow-open-close.png) no-repeat center center;
			background-size: contain;
			position: absolute;
			transform: rotate(-90deg);
			top: 20px;
			left: -80px;
			border: 3px solid rgba(214, 214, 214, 0.3);
			border-radius: 50%;
		}
	}
}

/* Content links */
#slideContent {
	width: auto;
	height: 100%;
	padding: 0;
	margin: 0;
	float: left;
	color: #EFEFEF;
	text-align: center;
}


/* Box with links  */
ul.quick-links {
	width: 240px;
	display: block;
	text-align: center;
	overflow: hidden;
	border-radius: 0 20px 20px 0;
	background: #013d32;

	li {
		background: none;
		margin: 0 0 5px 0;
		padding: 0;
		display: block;
		width: 90%;
		box-shadow: inset 5px 0 0 #fff;

		a {
			// https://cssgradient.io/
			background: rgb(0, 68, 56);
			background: linear-gradient(274deg, rgba(0, 68, 56, 1) 0%, rgba(28, 65, 110, 0) 100%);

			color: $links-colour;
			font-size: 18px;
			display: block;
			text-align: left;
			padding: 8px 10px 8px 45px;
			transition: .5s;

			&:hover {
				background-color: $link-bg-colour-hover;
				text-decoration: none;
				color: #fff;
				transition: .5s;
			}
		}
	}
}

@media only screen and (min-width: 750px) {

	#slideContent {
		display: table !important;
	}

	ul.quick-links {
		vertical-align: middle;
		display: table-cell;
	}

}

/* Change the arrow  */
.open span::before {
	transform: rotate(90deg) !important;
}

.flip {
	display: none;
}

@media only screen and (max-width: 750px) {
	#slideOut {
		position: absolute;
		top: 44px;
		min-height: 0px;
		left: 0;
		right: 0;
		width: 100%;
		// padding: 0px 3% 0 2%;
		overflow: visible;
		height: auto !important;
		box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08);
		border-radius: 0 0 10px 10px;
		// margin: 0 5% !important;
		background: $mobile-tab-color;
		padding: 5px 0;
	}

	#slideClick {
		display: none;

		span {
			transform: rotate(0);
			position: absolute;
			top: 0;
			left: 0;
			width: auto;
			height: auto;
			margin-left: 0;
			margin-top: 0;
			position: relative;
		}
	}

	#slideContent {
		float: none;
	}

	ul.quick-links {
		width: 100%;

		li {
			a {
				padding: 6px 0 6px 0;
				text-align: center;
				background-position: 8% 10px;
			}
		}
	}

	.flip {
		z-index: 800;
		margin: 0;
		padding: 0 0 5px 0;
		cursor: pointer;
		font-size: 16px;
		color: #fff;
		display: block;

		img {
			display: block;
			margin: 0 auto;
			transform: rotate(-90deg);
			width: 20px;
			height: 20px;
		}
	}

	.open img {
		transform: rotate(90deg);
	}
}