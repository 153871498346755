@media only screen and (max-width: 1550px) {
	footer:before {opacity: .1;}

	#top-boxes-sec .first-box h2, #top-boxes-sec .second-box h2, #top-boxes-sec .third-box h2 {font-size: 30px;}

}
	
	@media only screen and (max-width: 1500px) {

	#large-btns-sec .first-btn a h2.btn-title, #large-btns-sec .second-btn a h2.btn-title, #large-btns-sec .third-btn a h2.btn-title {font-size: 25px!important;}

	#top-boxes-sec .first-box ul li p.box-title, #top-boxes-sec .second-box ul li p.box-title, #top-boxes-sec .third-box ul li p.box-title {font-size: 22px;}

	#top-boxes-sec .first-box ul li:before, #top-boxes-sec .second-box ul li:before, #top-boxes-sec .third-box ul li:before {display: none;}

}

@media only screen and (max-width: 1366px) {

	#top-boxes-sec p.box-desc {padding: 0!important;}

	#news-diary-sec h2 {font-size: 30px!important; text-indent: 0!important;}
	#SideHeadingDiary ul.SideList li p.title, #SideHeadingNews ul.SideList li p.title {width: 100%;}

	#insta-box {
		h2 {
			&:before {display: none;}
		}
	}

} // end of 1366px

@media only screen and (max-width: 1250px) {

	#top-boxes-sec p.box-desc {font-size: 15px;
		&:before, &:after {display: none!important;}
	}

	#top-boxes-sec p.box-title {text-indent: 0!important;
		&:before {display: none!important;}
	}

	#top-boxes-sec ul li {
		 &:before, &:after {display: none!important;}
	}

	#top-boxes-sec p.box-title {font-size: 20px!important;}

	#top-boxes-sec .first-box ul li, #top-boxes-sec .second-box ul li, #top-boxes-sec .third-box ul li {height: auto; margin: 10px auto;}

	#SideHeadingNews ul.SideList:before, #SideHeadingDiary ul.SideList:before {opacity: .3;}

	#large-btns-sec ul { text-align: center!important; margin: 0 auto;
			li {width: 40%!important; margin: 30px}
	}

	footer{ height: auto;padding: 50px 0;
			&:before {display: none;}
		.wrapper1140 {width: 90%;
			h2, p, a { text-align: center; margin: 10px auto; display: block; float: none;
				&:before {display: none!important;}
			}
			a { word-break: break-all;}
		}
	}

	.sub-footer  { height: auto; line-height: normal; padding: 5px 0;
		p { text-align: center; margin: 5px auto; float: none;}
		#credit, .copyright { float: none; }
}

}

@media only screen and (max-width: 990px) {

	#top-boxes-sec {padding: 30px 0;}

	.first-box, .second-box, .third-box {display: block!important; width: 100%!important; margin: 50px auto!important;}

	#news-diary-sec .diary-box, #news-diary-sec .news-box, #news-diary-sec #insta-box {display: block; width: 100%; margin: 30px auto 0!important;}

	#large-btns-sec .first-btn, #large-btns-sec .second-btn, #large-btns-sec .third-btn {display: block; width: 90%!important; margin: 20px auto!important}

	//Search Form
	#FS{ display: none;}
	.translate { display: none; }

	// Header
	header{ height: auto; overflow: hidden;
		.wrapper{padding: 0;}
	}

	#logo-index  {margin: 90px auto 20px; float: none; display: block; max-width: 65%;
		a {
			img#logo{}
		}
	}

	.partnership-trust {display: none;}

				// -----------------------------------//
				// GENERAL STYLES
				// -----------------------------------//


	// Body
	body {min-width: 250px;	overflow-x: hidden;}

	h1 {font-size: 2em;}

	// Wrappers
	.wrapper {width: 100%;}

	// Mainholder
	#mainholder{width: 100%;padding: 0;}

	// Contents
	.Content1Column,
	.Content2Column,
	.Content3Column{width: 90%; padding: 30px; margin: 5% auto; float: none; display: block;}

	// Col Left and Right
	.ColumnLeft,
	.ColumnRight{width: 90%; padding: 30px; margin: 5% auto; float: none; display: block;}

	//Home page alert
	.modal-home{
		.message-modal{width: 90%; margin: 0 5%; left: 0; top: 20%; padding: 0;
			.modal-content{
				.modal-text{padding: 3%;}
				a.close-modal{position: absolute; top: 3%; right: 3%; background: #fff; padding: 3px; border-radius: 0px 0px 0px 7px;}
			}
		}
	}

	// Global
	img {max-width: 100%;height: auto;}
	table {max-width: 100%;}

	//Responsive tables
	table.tableborder{width: 100%; overflow: scroll; white-space: nowrap; table-layout: fixed;position: relative;
		&:before{content: "";width: 30px;height: 100%;position: absolute;top: 0;right: -1px;
			background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(0,0,0,0.2) 100%);
			background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(0,0,0,0.2) 100%);
			background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(0,0,0,0.2) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#33000000',GradientType=1 );
		}
		tbody{width: 100%; float: left; white-space: normal; overflow-x: scroll;}
	}

	// Calendar
	#calendar {
			.fc-header td {display: block;width: 100%;}
			.fc-button-content {font-family: Arial;}
			.fc-header-right {text-align: left;}
			.fc-header-center {text-align: left;}
	}

	// Forms
	form div input[type="text"], form div textarea, form span textarea, form div input[type="textarea"], form div input[type="password"] {width: 200px;}
	form div span.button {padding-left: 0;}

	#awards { display: none; }


} // end of 990px

			// -----------------------------------//
			// MOBILE
			// -----------------------------------//

@media only screen and (max-width: 750px) {

	header {padding: 40px 0;}
}


@media only screen and (max-width: 600px) {

	#large-btns-sec .first-btn a h2.btn-title, #large-btns-sec .second-btn a h2.btn-title, #large-btns-sec .third-btn a h2.btn-title {overflow: visible; display: block; margin: 0px auto 20px; height: auto;}

// to give overlay to mobile Slider
	.backstretch {position: relative;
  	 	&:before {position: absolute;content: ''; background: rgba(0,0,0,.3); left: 0px;top:0px; width:100%; height: 100%; opacity: .5; z-index: -1;}
    img  { top: 0px !important; }
	}

} // end of 600px

@media only screen and (max-width: 650px) and (orientation:landscape) {
	.body_3844 #slider {min-height: 150vh!important; height: 150vh!important;}
}

			// -----------------------------------//
			// BROWSER SPECIFIC
			// -----------------------------------//

// IE fixes
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

	#element {property: value;}

} // end of internet explorer
