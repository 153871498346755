#slider {
  position: relative;
  height: 515px;
  overflow: hidden;
  width: 100%;
  overflow: hidden;

  .wrapper {
    width: 100%;
    max-width: 100%;
    height: auto;
    background: red ;
    bottom: 0;
    position: absolute;
  }

  .wrapper1435 {
    width: 1435px;
    max-width: 90%;
  }

  &:before {
    @include before(100%, 450px);
    @include bg(url(/i/design/gradient.png), contain);
    background-repeat: repeat-x;
  }
}

.backstretch {
  img {
    top: 0px !important;
    animation: zoom 40s ease infinite forwards
  }
}

// @keyframes zoom {
//   from {transform: scale(1);}
//   to {transform: scale(1.4);}
// }