#large-btns-sec {background-color: rgb(228, 228, 228); padding: 80px 0;

	.first-btn, .second-btn, .third-btn {width: calc(100% / 3 - 20px); height: 435px; display: inline-block; z-index: 99; position: relative; text-align: center; vertical-align: top; box-sizing: border-box; box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.29);	height: 260px; position: relative; padding: 0; transition: .8s;

		&:before { @include before(100%, 100%); background: #000; opacity: .65; z-index: -1;}
		&:after { @include before(15px, 8px); @include bg(url(/i/design/arrow-head.png), contain); bottom: 36px; right: 9%; z-index: 99999; transition: .3s;}

		a {@include box(100%); display: block; text-decoration: none; position: relative; padding: 55px 20px 0; color: #fff;

			h2.btn-title {color: #fff; overflow: hidden; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; height: 45px; margin: 0 auto 20px; font-weight: bold; font-size: 32px;}

			p.btn-desc {color: #fff; overflow: hidden; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; height: 50px;}

			// OI OIIIIII HERES THE ARROW CODE

			&:before { @include before(80%, 1px); background: #fff; bottom: 40px; left: 0; right: 0; margin: 0 auto; z-index: 99999;}
			&:after { @include before(40%, 1px); background: #fff; bottom: 39px; right: 10%; z-index: 99999; transition: .3s;}
		}
	}

	ul {
		&:hover {
			li { 
				&:hover{ filter: brightness(110%); transform: translateY(-5px); box-shadow: inset 0 0 0 130px rgba(#000, .3);}
					&:not(:hover) { opacity: .5;}

				a { 
					&:before { @include before(80%, 1px); background: #fff; bottom: 40px; left: 0; right: 0; margin: 0 auto; z-index: 99999;}
					&:after {  @include before(80%, 1px); background: #fff; bottom: 39px; right: 10%; z-index: 99999; transition: .3s;}
				}

				&:after {transform: translateX(3px);}
				
			}
		}
	}
}

	.first-btn {@include bg(url(/i/graphics/bottom-btns/1.jpg), cover);}
	.second-btn {@include bg(url(/i/graphics/bottom-btns/2.jpg), cover); margin: 0 25px;}
	.third-btn {@include bg(url(/i/graphics/bottom-btns/3.jpg), cover);}