// @include bg(url(/i/design/...), cover);
@mixin bg ($bg, $bsz) {
	background: ($bg) no-repeat center;  background-size: ($bsz);
}

// @include box(200px, 300px); // @include box(200px);
@mixin box ($w, $h: $w) {
	width: $w; height: $h;
}

//  &:before { @include before(100%, 100%); @include bg(url(/i/design/...), cover);  } 
@mixin before ($w: 100%, $h: 100%, $display: block, $pos: absolute, $content: "", $bg: '(url/i/design...)', $bgz: contain) {
	content: $content; display: $display; position: $pos; 
		@include box($w, $h);  
		@include bg($bg, $bgz); 
}

// translate trick
@mixin center {
	position: absolute;	top: 50%;left: 50%;	transform: translate(-50%,-50%);
}