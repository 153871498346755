@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;900&display=swap');

$link-color: #105CA7;
$h1-color: #134383;
$h2-color: #87B3D8;
$h3-bg-color: #134383;
$h4-bg-color: #134383;

// -----------------------------------//
// GLOBAL STYLES
// -----------------------------------//

// BODY
body {
	color: #222;
	font-size: 17px;
	font-family: 'Montserrat', sans-serif;
	text-align: center;
	line-height: 1.528571429;
	padding: 0px;
	margin: 0;
	position: relative;
	overflow-x: hidden;
}

// LINKS
body a {
	color: $link-color;
	text-decoration: none;
	transition: .5s;
}

body a:hover,
body a:focus {
	text-decoration: underline;
}

// LINKED IMAGES
a img,
img a,
img,
p a img {
	border: 0;
	border-bottom: 0;
	text-decoration: none;
	vertical-align: middle;
}

a img:hover {
	opacity: 0.8
}

// HEADINGS
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 500;
}

h1 {
	font-size: 48px;
	margin: 0 0 20px 0;
	color: $h1-color;
	line-height: 1.2;
}

// ip headings
#mainholder h1 {
	&:first-of-type {
		display: none;
	}
}

.body_3844 h1.slider-heading {
	font-weight: 500;
	text-align: center;
	word-spacing: 20px;
	padding: 33px 5% 33px 5%;
	display: none;
}

.slider-heading {
	display: block;
	width: 100%;
	background: rgba(#105CA7, 0.8);
	text-align: left;
	position: absolute;
	bottom: 0px;
	left: 0;
	right: 0;
	margin: 0 auto;
	color: #fff;
	font-size: 38px;
	font-weight: 900;
	padding: 33px 5% 33px 12%;
}


h2 {
	font-size: 32px;
	margin: 25px 0 20px 0;
	color: $h2-color;
	line-height: 1.3;
	font-weight: 300;
}

h3 {
	font-size: 23px;
	margin: 25px 0 10px 0;
	padding: 5px 10px 3px 10px;
	background: $h3-bg-color;
	color: #fff;
	font-weight: 300;
}

h4 {
	@extend h3;
	background-position: 10px center;
	background-repeat: no-repeat;
	padding-left: 30px;
	background-color: $h4-bg-color;
}

h4.open {
	background-image: url(/i/design/expanding-faq/minus-white.png);
}

h4.closed {
	background-image: url(/i/design/expanding-faq/plus-white.png);
}

h5 {
	font-size: 12px;
}

h6,
small {
	font-size: 10px;
}

h1+h2 {
	margin-top: 0;
	border: 0;
	padding: 0;
}

h1+p,
h2+p,
h3+p {
	margin-top: 0;
}

// PARAGRAPHS AND LIST ITEMS
p {
	margin: 12px 0;
	padding: 0;
}

li {
	margin: 2px 0;
	padding: 0 0 0 23px;
	background: url(/i/icons/bullet_black.png) no-repeat top 6px left 3px;
}

// FORMS
form {
	margin: 0;
	padding: 0;
	@extend .clearfix;
}

fieldset {
	border: 1px solid #c0c0c0;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
}

legend {
	border: 0;
	padding: 0;
	white-space: normal;
	*margin-left: -7px;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button;
	cursor: pointer;
	*overflow: visible;
	/* 4 */
}

button[disabled],
html input[disabled] {
	cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
	box-sizing: border-box;
	padding: 0;
	*height: 13px;
	*width: 13px;
}

input[type="search"] {
	-webkit-appearance: textfield;
	box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

textarea {
	resize: vertical;
	overflow: auto;
	vertical-align: top;
}

label {
	float: left;
	margin: 10px 0;
	width: 90px;
	display: inline;
}

form .formfield {
	margin: 10px;
	float: left;
	display: inline;
	width: 200px;
	border: 1px solid #CCC;
	padding: 2px;
}

form div {
	margin: 15px 0;
	display: block;
	clear: left;
	vertical-align: top;
}

form div input[type="text"],
form div textarea,
form span textarea,
form div input[type="textarea"],
form div input[type="password"] {
	border: 1px solid #CCC;
	padding: 4px;
	width: 300px;
	margin: 0;
	background-color: #EFEFEF;
}

input[type="text"]:focus,
form div textarea:focus,
form span textarea:focus,
form div input[type="textarea"]:focus,
form div input[type="password"]:focus {
	border-color: #000;
}

form div span.label label,
form div span.label,
span.spacer,
span.multiple span {
	width: 180px;
	float: left;
	margin: 0 0 0 0;
}

form div span.multiple {
	float: left;
}

form div span.button {
	padding-left: 180px;
	background: transparent;
	border: 0;
}

form div span.button input {
	background: #666;
	color: #FFF;
	margin: 0;
	padding: 8px 10px;
	font-weight: bold;
	border: 0;
}

form div span.button input:hover {
	background: #888;
}

form div.clear {
	clear: both;
	padding-top: 5px;
}

hr {
	height: 1px;
	border-width: 0;
	color: #f2f2f2;
	background-color: #f2f2f2;
}

// -----------------------------------//
// IE WARNING
// -----------------------------------//

#ie-warning {
	width: 100%;
	background: #b20000;
	color: #fff;
	overflow: hidden;
	padding: 0 0 10px 0;
	transition: .5s;
	display: block
}

#ie-warning p {
	margin: 5px auto;
}

#ie-warning a {
	color: #fff;
	text-decoration: underline;
	cursor: pointer;
}

// -----------------------------------//
// WRAPPER
// -----------------------------------//

#wrapper {
	margin: 0 auto;
	text-align: center;
}

.wrapper {
	width: 1435px;
	margin: 0 auto;
	height: 100%;
	@extend .clearfix;
	position: relative;
	max-width: 90%;
}

.wrapper1140 {
	width: 1140px;
	margin: 0 auto;
	height: 100%;
	@extend .clearfix;
	position: relative;
	max-width: 90%;
}

.wrapper-inside {
	@include box(100%);
	max-width: 2000px;
	margin: 0 auto;
	@extend .clearfix;
	position: relative;
}

// -----------------------------------//
// HEADER
// -----------------------------------//

header {
	padding: 25px 0;
	position: relative;
}

header>.wrapper {
	padding: 0;
	position: relative;
}

header h1 {
	margin: 0;
	background: none;
}

#logo-index {
	margin: 0;
	padding: 0;
	float: left;
}

// -----------------------------------//
// SEARCH FORM
// -----------------------------------//

#FS {
	margin: 0;
	padding: 0;
	@extend .clearfix;
	position: absolute;
	right: 12%;
	top: 130px;
}

#FS #search-field {
	vertical-align: top;
	padding: 3px 0 0 20px;
	font-weight: 200;
	background-color: #fff;
	border: 2px solid #dcdcdc;
	width: 275px;
	line-height: 30px;
	height: 30px;
	color: #222;
}

#FS #search-submit {
	position: absolute;
	right: 7px;
	top: 6px;
	padding: 0;
}

// -----------------------------------//
// SEARCH RESULTS
// -----------------------------------//

$search-highlight-background-color: lightblue;
$search-highlight-color: darkblue;
$search-highlight-font-weight: bold;
$search-highlight-font-size: 15px;
$search-odd-tr-background: #ececec;

.search-subject-box {
	display: inline-block;
	background: #f1f1f1;
	padding: 5px 15px;
	font-size: 16px;
	border-radius: 4px;
	border: 1px solid #ccc;
	text-transform: uppercase;
}

.search-numbers {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;

	span {
		background: #f1f1f1;
		padding: 3px 8px;
		margin: 0 3px;
		border-radius: 2px;
		border: 1px solid #ccc;
	}
}

// -----------------------------------//
// MAIN HOLDER
// -----------------------------------//

.body_3844 #mainholder {
	padding: 0;
	margin: 0;
	background: #fff;
}

#mainholder {
	width: 100%;
	margin: 0px auto;
	padding: 30px 0 30px 0;
	clear: both;
	text-align: left;
	@extend .clearfix;
	position: relative;

	&:before {
		@include before(900px, 900px);
		@include bg(url(/i/design/mainholder-bg.png), contain);
		opacity: .05;
		z-index: 0;
		right: 0;
		bottom: 0;
	}
}

// -----------------------------------//
// CONTENT COLUMNS
// -----------------------------------//

//Content 1
.Content1Column {
	width: 80%;
	max-width: 1490px;
	margin: 0 auto;
	padding: 30px;
	min-height: 50vh;
	text-align: left;
	box-sizing: border-box;

	img {
		max-width: 100%;
		height: auto;
	}

	h1 {}

	h2 {}
}

//Content 2
.Content2Column {
	width: 55%;
	margin: 0 0 0 10%;
	padding: 30px;
	min-height: 50vh;
	text-align: left;
	display: inline-block;
	float: left;
	box-sizing: border-box;

	img {
		max-width: 100%;
		height: auto;
	}

	h1 {}

	h2 {}

	tr,
	td {
		border: 1px solid #000 !important;
	}

	.tablenoborderpadded {
		border: 0 !important;

		tr,
		td {
			border: 0px !important;
		}
	}
}

//Content 3
.Content3Column {
	width: 430px;
	margin: 0;
	padding: 0;
	float: left;
	min-height: 350px;
	text-align: left;

	img {
		max-width: 100%;
		height: auto;
	}
}

.HomeAlert .Content1Column {
	float: none;
	margin: 0 auto;
}

// -----------------------------------//
// COLUMN LEFT & COLUMN RIGHT
// -----------------------------------//

.ColumnLeft {
	width: 250px;
	margin: 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	float: left;
}

.ColumnRight {
	width: 20%;
	margin: 0 13% 0 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	display: inline-block;
	float: right;
	box-sizing: border-box;
}

.ColumnLeft ul,
.ColumnRight ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.ColumnLeft nav ul a:link,
.ColumnRight nav ul a:visited,
.ColumnRight nav ul a:link,
.ColumnRight nav ul a:visited {
	display: block;
}

.ColumnLeft li {
	border-bottom: 1px solid #EEE;
}

// Hack to fix IE/Win's broken rendering of block-level anchors in lists
html>body .ColumnLeft li {
	border-bottom: none;
}

// Fix for browsers that don't need the hack

// -----------------------------------//
// SIDE COLUMN > LIST SECTIONS
// -----------------------------------//

// Container
.SideHeading {
	margin: 0;
	padding: 0;
	text-align: center;
}

// List
ul.SideList {
	margin: 0;
	padding: 0;
	border: 0;
	list-style: none;
	text-align: left;
}

ul.SideList li {
	margin: 0;
	padding: 0;
	background: none;
}

ul.SideList li a {
	margin: 0;
	padding: 0px;
	color: #000;
	transition: .5s;
	font-weight: normal;
	display: block;
}

ul.SideList li a:hover {
	transition: .5s;
	text-decoration: none;
}

ul.SideList li a.child {
	background: url(/i/design/svg/ic-related-pages.svg) left 35px center no-repeat;
	padding-left: 70px !important;
	border-bottom: 1px solid rgba(255, 255, 255, .5);
}

// Date and Title
ul.SideList li p {
	margin: 0;
	padding: 0;
}

ul.SideList p.date {
	float: left;
	text-align: center;
	font-size: 20px;
	line-height: 130%;
	transition: .5s;

	span {
		display: block;
		transition: .5s;
	}
}

ul.SideList p.title {
	float: right;
	width: 100%;
	font-size: 16px;
	transition: .5s;

	// Truncate
	span {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
}

// More links
.SideHeading a.more {
	display: inline-block;
	font-size: 18px;
	padding: 0px;
	transition: .5s;

	&:hover {
		transition: .5s;
		text-decoration: none;
	}
}

// -----------------------------------//
// NEWS, DIARY
// -----------------------------------//

#news-diary-sec {
	padding: 0 0 70px 0;

	.diary-box,
	.news-box,
	#insta-box {
		width: calc(100% / 3 - 20px);
		display: inline-block;
		z-index: 99;
		position: relative;
		text-align: center;
		vertical-align: top;
		box-sizing: border-box;
		overflow: hidden;

		h2 {
			text-align: center;
			width: 100%;
			display: block;
			color: #285F95;
			margin: 20px auto;

			span {
				font-weight: bold;
			}
		}
	}
}

.diary-box {
	margin: 0 25px;
}

//Diary
#SideHeadingDiary {
	@include bg(url(/i/graphics/news-diary/diary-bg.jpg), cover);
	outline: 2px solid rgba(0, 0, 0, .2);
	outline-offset: -10px;
	padding: 20px 30px;
	box-sizing: border-box;
	border: 3px solid #e8e8e8;
	position: relative;
	z-index: 99;
	width: 100%;
	overflow: hidden;

	&:before {
		@include before(100%, 100%);
		background: #fff;
		opacity: .9;
		z-index: -1;
		left: 0;
		top: 0;
	}

	ul.SideList {
		z-index: 99;
		position: relative;

		&:before {
			@include before(300px, 300px);
			@include bg(url(/i/design/logo-dark.png), contain);
			opacity: .9;
			z-index: -1;
			right: -140px;
			bottom: -140px;
			opacity: .8;
		}

		li {
			padding: 10px 0 10px 0;
			;
			background: none;
			border-bottom: 1px solid rgba(34, 34, 34, 0.2);
			height: 100px;

			p.date {
				float: none;
				display: block;
				text-align: left;
				color: #285F95;
				font-weight: bold;
				padding: 0 30px;
				position: relative;
				margin: 0 auto 5px;

				&:before {
					@include before(2px, 15px);
					background: #285F95;
					bottom: 1px;
					left: 10px;
					top: 6px;
					z-index: 99999;
				}
			}

			p.title {
				float: none;
				text-align: left;
				padding: 0 30px;
				width: 70%;
			}

			&:hover {
				p.date {
					transform: translateX(10px);
				}
			}
		}
	}
}

//News
#SideHeadingNews {
	@include bg(url(/i/graphics/news-diary/news-bg.jpg), cover);
	outline: 2px solid rgba(#fff, .2);
	outline-offset: -10px;
	padding: 20px 30px;
	box-sizing: border-box;
	position: relative;
	z-index: 99;
	width: 100%;

	&:before {
		@include before(100%, 100%);
		background: #285F95;
		opacity: .9;
		z-index: -1;
		left: 0;
		top: 0;
	}

	ul.SideList {
		z-index: 99;
		position: relative;

		&:before {
			@include before(180px, 190px);
			@include bg(url(/i/design/logo-white.png), contain);
			opacity: .9;
			z-index: -1;
			right: -50px;
			bottom: -30px;
			opacity: .8;
		}

		li {
			padding: 10px 0 10px 0;
			;
			background: none;
			border-bottom: 1px solid rgba(255, 255, 255, 0.445);
			height: 100px;

			p.date {
				float: none;
				display: block;
				text-align: left;
				color: #fff;
				font-weight: bold;
				padding: 0 30px;
				position: relative;
				margin: 0 auto 5px;

				&:before {
					@include before(2px, 15px);
					background: #fff;
					bottom: 1px;
					left: 10px;
					top: 6px;
					z-index: 99999;
				}
			}

			p.title {
				float: none;
				text-align: left;
				padding: 0 30px;
				color: #fff;
				width: 70%;
			}

			&:hover {
				p.date {
					transform: translateX(10px);
				}
			}
		}
	}
}

// -----------------------------------//
// INSTAGRAM BOX
// -----------------------------------//

#insta-box {
	.insta-feed {
		@include box(100%);
		height: 445px;
		background: #285F95;
		display: block;
		position: relative;
		overflow-y: scroll;

		iframe {
			width: 95%;
			position: absolute;
			left: 0;
			right: 0;
			margin: 0 auto;
		}
	}

	h2 {
		text-indent: 50px;
		position: relative;
		color: #285F95 !important;

		&:before {
			@include before(21px);
			@include bg(url(/i/design/insta-ic.png), contain);
			z-index: -1;
			left: 15%;
			top: 0;
		}
	}
}

//Related pages
#SideHeadingPages {
	overflow: hidden;
	background: #105CA7;
	margin: -50px auto 0;
	box-shadow: 0 0 0 5px rgba(255, 255, 255, .2);
	outline: 2px solid rgba(255, 255, 255, .5);
	outline-offset: 15px;

	h2 {
		display: block;
		text-align: center;
		font-size: 28px;
		padding: 20px 0;
		margin: 0;
		color: #fff;
		text-align: center;
		font-weight: bold;
		position: relative;

		&:before {
			@include before(80%, 1px);
			background: #fff;
			bottom: 0;
			left: 0px;
			right: 0;
			margin: 0 auto;
			opacity: .2;
		}
	}

	ul.SideList {
		padding: 20px 0 50px 0;
		;

		li {
			a {
				padding: 7px 0 7px 30px;
				font-size: 18px;
				color: #fff;

				&:hover {
					text-indent: 5px;
					background: #105CA7;
					background: linear-gradient(90deg, rgba(28, 65, 110, 0) 0%, rgba(135, 180, 216, 0.802) 100%);
					box-shadow: inset 5px 0 0 0 #87B3D8;
				}
			}
		}
	}
}

// -----------------------------------//
// HOME PAGE ALERT
// -----------------------------------//

//Modal
.modal-home {
	background: rgba(0, 0, 0, .4);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 8000;

	.message-modal {
		position: absolute;
		top: calc(50% - 300px);
		left: calc(50% - 465px - 40px - 10px);
		width: 930px;
		height: auto;
		margin: 0 30px;
		padding: 20px;
		background: rgba(255, 255, 255, .9);

		.modal-content {
			background: rgba(255, 255, 255, .9);
			background-position: -120px 50px;
			box-shadow: inset 0 0 0 1px #fff;
			display: inline-block;
			width: 100%;
			height: 100%;
			text-align: center;

			.modal-text {
				display: block;
				padding: 45px;

				p {
					margin: 0;
				}
			}

			a.close-modal {
				position: absolute;
				top: 31px;
				right: 31px;
			}
		}
	}
}

.hide-modal {
	display: none !important;
}

// -----------------------------------//
// AWARDS
// -----------------------------------//

#awards {
	width: 100%;
	overflow: hidden;
	background: #fff;
	padding: 13px 0;

	.CMSawards {
		overflow: hidden;
		width: 100%;
		height: 100%;
		margin: 0 auto !important;
		background: none !important;

		ul {
			margin: 0;
			padding: 15px 0;

			li {
				background: none;
				padding: 0 5px;
				margin: 0 20px;
				display: inline-block;

				img {
					max-height: 70px;
					transform: none !important;
				}
			}
		}
	}
}

// -----------------------------------//
// FOOTER
// -----------------------------------//

footer {
	clear: both;
	background: #999;
	padding: 50px 0 20px;
	overflow: hidden;
	position: relative;
	background: #105CA7;

	&:before {
		@include before(327px, 160px);
		@include bg(url(/i/design/footer-logo.png), contain);
		top: 0;
		left: 0px;
	}
}

footer>.wrapper1140 {
	color: #fff;
	text-align: left;
}

h2.footer-heading {
	padding: 0;
	margin: 0;
}

p.address {
	position: relative;
	float: left;
	margin: 0 0 0 60px;

	&:before {
		@include before(18px, 18px);
		@include bg(url(/i/design/address-ic.png), contain);
		top: 3px;
		left: -50px;
	}
}

p.telephone {
	position: relative;
	float: left;
	margin: 0px 0 0 135px;

	&:before {
		@include before(18px, 18px);
		@include bg(url(/i/design/telephone-ic.png), contain);
		top: 0;
		left: -50px;
	}
}

footer a[href^="mailto:"] {
	position: relative;
	float: right;
	color: #fff;
	margin: 0px 0 10px 0;

	&:before {
		@include before(18px, 18px);
		@include bg(url(/i/design/mail-ic.png), contain);
		top: 0;
		left: -50px;
	}
}

// -----------------------------------//
// SUB-FOOTER
// -----------------------------------//

.sub-footer {
	height: 20px;
	width: 100%;
	line-height: 20px;
	overflow: hidden;
	margin: 25px auto 0;

	p {
		font-size: 13px;
		color: #fff;
		float: left;
		margin: 0;
	}

	a {
		color: #fff;
	}

	.copyright {
		float: right;
	}

	#credit {
		float: right;
		margin: 0 15px 0 30px;

		&:before {
			@include before(22px, 22px);
			@include bg(url(/i/design/gsw.png), contain);
			top: -2px;
			right: -20px;
		}
	}
}

// -----------------------------------//
// CMS CONTENT: TABLES
// -----------------------------------//

$table-highlight-color: darkblue;
$table-highlight-background-color: lightblue;
$table-highlight-font-weight: bold;
$table-border-color: #000;

// -----------------------------------//
// CMS CONTENT: BLOCKQUOTES
// -----------------------------------//

blockquote p {
	text-align: center;
}

$blockquote-background-color: rgba(#87B3D8, 0.733);
$blockquote-border-color: rgba(#87B3D8, 0.733);
;
$blockquote-color: #fff;
$blockquote-speech-mark-color: #fff;
$blockquote-font-size: 26px;

// -----------------------------------//
// CALENDAR TABLE BACKGROUND
// -----------------------------------//

.fc-content {
	background: none;
}

// -----------------------------------//
// BROWSER DETECTION
// -----------------------------------//

#browserblast {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 40px;
	border-bottom: 1px solid #D4C790;
	font-family: sans-serif;
	font-size: 12px;
	font-weight: bold;
	line-height: 40px;
	color: #6d6647;
	text-align: center;
	background: #FAF4AF;
}

#browserblast a {
	color: green;
	text-decoration: underline;
}

.unsupported-browser {
	margin-top: 41px;
}

// -----------------------------------//
// FAQ SCRIPT
// -----------------------------------//

// h3.faq-open { background: #EFEFEF url(/i/design/expanding-faq/minus.png) right 12px center no-repeat; }
// h3.faq-closed { background: #EFEFEF url(/i/design/expanding-faq/plus.png) right 12px center no-repeat; }

// -----------------------------------//
// SWITCH MOBILE
// -----------------------------------//

#switch-mobile {
	width: 100%;
	margin: 0;
	padding: 20px 0;
	background: #EFEFEF;
	border-top: 1px solid #EBEBEB;
	border-bottom: 1px solid #EBEBEB;
	text-align: center;
	color: #777;
	font-family: sans-serif;
	font-size: 12px;
	display: block;
	clear: both;
}

#switch-mobile a {
	text-decoration: underline;
}