#top-boxes-sec {
  padding: 50px 0 20px 0;

  .first-box,
  .second-box,
  .third-box {
    width: calc(100% / 3 - 20px);
    display: inline-block;
    outline: 2px solid rgba(0, 0, 0, .2);
    outline-offset: 7px;
    box-shadow: inset 0 0 0 5px rgba(255, 255, 255, .9);
    z-index: 99;
    position: relative;

    &:before {
      @include before(100%, 100%);
      background: #86b2d8;
      opacity: .9;
      z-index: -1;
    }

    h2 {
      text-align: center;
      z-index: 999;
      display: block;
      color: #fff;
      margin: 0 auto;
      padding: 30px 10px;
      background: rgba(#0f549a, .5);
      font-weight: 300;
      letter-spacing: .6px;
    }

    ul {
      padding: 20px 40px 0;
      box-sizing: border-box;
      z-index: 0;

      li {
        background: none;
        position: relative;
        height: 140px;
        padding: 0;
        transition: .5s;

        a {
          text-decoration: none;
        }

        &:before {
          @include before(15px, 8px);
          @include bg(url(/i/design/arrow-head.png), contain);
          bottom: 21px;
          right: -5px;
          z-index: 99999;
        }

        p.box-title {
          font-weight: bold;
          font-size: 27px;
          margin: 0;
          padding: 0;
          color: #fff;
          text-indent: 20px;
          position: relative;
          transition: .5s;

          &:before {
            @include before(5px, 20px);
            background: #fff;
            bottom: 1px;
            left: 0px;
            top: 10px;
            z-index: 99999;
          }
        }

        p.box-desc {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          height: 70px;
          position: relative;
          color: #fff;
          margin: 5px 0;
          padding: 0 20px;

          // OI OIIIIII HERES THE ARROW CODE

          &:before {
            @include before(110%, 1px);
            background: #fff;
            bottom: 1px;
            left: -5%;
            right: 0;
            margin: 0 auto;
            z-index: 99999;
          }

          &:after {
            @include before(60%, 1px);
            background: #fff;
            bottom: 0px;
            right: 0;
            z-index: 99999;
            transition: .3s;
          }

        }

        &:hover {
          &:before {
            transform: translateX(3px);
          }

          p.box-title {
            transform: translateX(10px);
          }

          p.box-desc {
            &:after {
              @include before(110%, 1px);
              background: #fff;
              bottom: 0px;
              right: 0;
              z-index: 99999;
              transition: .3s;
            }
          }

        }
      }
    }
  }

  .second-box {
    margin: 0 25px;
  }

  .first-box {
    @include bg(url(/i/graphics/top-boxes/1.jpg), cover);
  }

  .second-box {
    @include bg(url(/i/graphics/top-boxes/2.jpg), cover);
  }

  .third-box {
    @include bg(url(/i/graphics/top-boxes/3.jpg), cover);
  }

}